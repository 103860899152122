<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.listadooperarios') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <operarios-estados :activos="activos" :eliminados="eliminados" />
                </div>
                <div class="col-md-10">
                    <div class="row" style="gap: 25px;">
                        <Dropdown scrollHeight="600px" v-if="mostrareliminados == false" showClear
                            v-model="especialidadselec" :options="especialidades" @change="obtenerDatos()" filter
                            optionLabel="nombre" placeholder="Filtro especialidad">
                        </Dropdown>
                        <input style="width: 250px; height: 41px;" v-model="codigosFiltro" type="text" class="form-control" v-if="codigoActivado" placeholder="CPs separados por coma" @input="obtenerDatos()">                        
                    </div>
                    <p></p>
                    <tabla-datos v-if="mostrareliminados==false && permisoscargados" :columnas="columnas" :datos="operarios" :botoncrear="!permisomodif"
                        :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total"
                        @quieroDatos="obtenerDatos($event)"></tabla-datos>
                    <tabla-datos v-if="mostrareliminados==true && permisoscargados" :columnas="columnasdos" :datos="operarioseliminados" :botoncrear="!permisomodif"
                        :filtros="filtrosdos" :filtrosBuscador="filtrosBuscadordos" :total="eliminados"
                        @quieroDatos="obtenereliminados($event)"></tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import OperariosEstados from './listado/OperariosEstados.vue'
import TablaDatos from '../../TablaDatos.vue';
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        'operarios-estados': OperariosEstados,
        'tabla-datos': TablaDatos,
        Dropdown
    },
    data() {
        return {
            permisoscargados:false,
            permisomodif:false,
            codigosFiltro:'',
            operarios: [],
            activos: '',
            operarioseliminados:[],
            eliminados: '',
            especialidades:[],
            especialidadselec:'',
            mostrareliminados: false,
            parametros: null, // query params de DataTable
            parametrosdos: null, // query params de DataTable
            filtrosBuscador: ['nombre'], // filtros para el buscador
            filtrosBuscadordos: ['nombre'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre': { value: "", matchMode: FilterMatchMode.CONTAINS },

            },
            filtrosdos: { // filtros de columna
                'nombre': { value: "", matchMode: FilterMatchMode.CONTAINS },

            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idoperario',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar operario ' + id);
                                this.$router.push({
                                    name: 'Operario',
                                    params: {
                                        id
                                    }
                                });
                            }
                        },
                    ]
                }
            ],
            columnasdos: [
                {
                    header: 'Nombre',
                    field: 'nombre',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    sortable:false,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idoperario',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar operario ' + id);
                                this.$router.push({
                                    name: 'Operario',
                                    params: {
                                        id
                                    }
                                });
                            }
                        },
                        
                    ]
                }
            ],
        }
    },
     methods: {
        async obtenerDatos(event) {
            /* get  modulos/pwgsapi/index.php/operarios/simple */
            const api = new PwgsApi;
            var extra = '';
            if(this.codigosFiltro !=''){
                extra+='cp,'+[this.codigosFiltro]+',';
            }
            else if (this.especialidadselec) {
                extra+='especialidad,'+[this.especialidadselec.idespecialidad];
             }
            const datos = await api.get('operarios/simple,'+extra,event);
            this.operarios = datos.datos;
            this.total = datos.n_total_registros;
            console.log('operarios', this.operarios);
            this.activos = this.total;
            this.mostrareliminados = false;
        },
         async obtenerEspecialidades() {
             //GET /pwgsapi/index.php/especialidades
             const api = new PwgsApi();
             let aux = await api.get('especialidades');
             this.especialidades = aux.datos;
        },
        async eliminar(id) {
            /*delete  modulos/pwgsapi/index.php/operarios/:id */
            const api = new PwgsApi;
            await api.delete('operarios/' + id);
            this.obtenerDatos();
         },
         async obtenereliminados(parametrosdos) {
             let params = parametrosdos;
             const api = new PwgsApi();
             const datos = await api.get('operarios/eliminados',params);
             this.eliminados = datos.n_total_registros;
             this.operarioseliminados = datos.datos;
         },
         cambiaractivos() {
            this.mostrareliminados = false;
         },
         cambiareliminados() {
            this.mostrareliminados = true;
         },
         async recuperar(id) {
            //PUT  modulos/pwgsapi/index.php/operarios/:id
            const api = new PwgsApi();
            let subidadatos = { estado_operario: "" };
            await api.put('operarios/' + id, subidadatos);
            this.obtenerDatos();
            this.cambiaractivos();
        },
        async comprobarCodigoActivado(){
            const api = new PwgsApi();
            const res = await api.get('parametros-pwgs/operario_zona_cp');
            if(res.operario_zona_cp==1)this.codigoActivado = true;
            const modificar = await api.get('operarios/permiso-modificar');
            if(modificar == 1){
                this.permisomodif = true;
            }else{
                this.permisomodif = false;
            }
            console.log('permisomod', this.permisomodif);
            if(this.permisomodif){
                let nuevo = {
                            nombre: 'Eliminar',
                            class: 'eliminaroperario',
                            tipo: 'danger',
                            nombreId: 'idoperario',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar cia ' + id);
                                if (confirm('¿Desea eliminar al operario?')) {
                                    this.eliminar(id);
                                }
                            }
                        };
                this.columnas[1].acciones.push(nuevo);
                let nuevo2 = {
                            nombre: 'Recuperar',
                            class: 'recuperaroperario',
                            tipo: 'success',
                            nombreId: 'idoperario',
                            icono: 'fa fa-recycle',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                if (confirm('¿Desea recuperar al operario?')) {
                                    this.recuperar(id);
                                }
                            }
                        };
                this.columnasdos[1].acciones.push(nuevo2);
            }
            this.permisoscargados = true;
        }
    },
    mounted() {
        this.comprobarCodigoActivado();
        this.obtenerDatos();
        this.obtenereliminados();
        this.obtenerEspecialidades();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
.oculta {
    display: none !important;
}
.p-dropdown-panel{
    border: 1px solid;
    color: #c1c1c1;
}
</style>